// @ts-strict-ignore
import { decomposeCellId, RowField, ValidReportRawData } from 'algo-react-dataviz';
import { Cell, ReportNode, ReportRawDataNode } from '../shared/dataTypes';
import { AppState } from './configureStore';
import { getLastRow } from './ReportActionCreators';

export const needsFirst = (n: ReportNode<Cell[]>, _expandedRowIds?: string[]): boolean => {
  const expandedRowIds = _expandedRowIds || n.props?.expandedRowIds;

  return (
    (0 < n.props?.numChildren && n.props?.rowIdStart !== n.children?.[0]?.payload[0][0]) ||
    (0 < n.children?.length &&
      (expandedRowIds || []).includes(n.children[0].payload[0][RowField.ROW_HASH]) &&
      needsFirst(n.children[0], expandedRowIds))
  );
};

const needsLast = (root: ReportNode<Cell[]>, row: ReportNode<Cell[]>): boolean => {
  if (row.id === root.id) {
    // Recursive calls have gotten all the way up to the root without finding a node that needs
    // more children or siblings loaded.
    return false;
  }

  const rowNeedsChildren =
    root.props.expandedRowIds?.includes(row.payload[0][RowField.ROW_HASH]) &&
    row.props.rowIdStop !== row.children?.[row.children.length - 1].payload[0][RowField.ROW_HASH];

  if (rowNeedsChildren) return true;

  // row doesn't need children, but parent may need children, or (SD-3838) parent may not be the
  // last child of its parent.
  const parent = findNode(root, node => node.children?.some(c => c.id === row.id));
  return needsLast(root, parent);
};

export const needsFirstLastData = (root: ReportNode<Cell[]>) => ({
  first: needsFirst(root),
  last: needsLast(root, getLastRow(root)),
});

const mergeChildren = (
  aOrig: ReportNode<Cell[]>[],
  bOrig: ReportNode<Cell[]>[],
): ReportNode<Cell[]>[] => {
  // Both a and b must be sorted in order of id. When there is an id found in both a and b, the
  // properties of a will be included in the returned value; the properties of b will be discarded.

  const returnable: ReportNode<Cell[]>[] = [];
  const a = [...(aOrig || [])];
  const b = [...(bOrig || [])];

  while (a.length || b.length) {
    if (!a.length) {
      // Push all of b onto returnable and empty b. This is the last time through the loop.
      returnable.push(...b);
      b.splice(0, b.length);
    } else if (!b.length) {
      // Push all of a onto returnable and empty a. This is the last time through the loop.
      returnable.push(...a);
      a.splice(0, a.length);
    } else if (a[0].id === b[0].id) {
      // Remove a[0] and b[0] from a and b, recursively merge them, and add them to returnable.
      const a0 = a.shift();
      const b0 = b.shift();
      const firstItemWithChildrenMerged =
        a0.children?.length || b0.children?.length
          ? { ...a0, children: mergeChildren(a0.children, b0.children) }
          : a0;

      returnable.push(firstItemWithChildrenMerged);
    } else if (a[0].id < b[0].id) {
      // Remove a[0] from a and add it to returnable.
      returnable.push(a.shift());
    } else {
      // Remove b[0] from b and add it to returnable.
      returnable.push(b.shift());
    }
  }

  return returnable;
};

export const merge = (state: ReportNode<Cell[]>, payload: ReportRawDataNode) => ({
  ...payload.data,
  children: mergeChildren(state.children, payload.data.children),
});

interface RowInfo {
  index: number;
  childrenIds: number[];
}

// Get a list of all the row indexes in memory, in order.
export const getAllRowIndexes = (data: ReportNode<Cell[]>): RowInfo[] => [
  { index: data.id, childrenIds: data.children?.map(c => c.id) || [] },
  ...(data.children?.flatMap(c => getAllRowIndexes(c)) || []),
];

export const getAllRowUuids = (data: ReportNode<Cell[]>): string[] => [
  ...(data.payload ? [data.payload[0][RowField.ROW_HASH]] : []),
  ...(data.children?.flatMap(c => getAllRowUuids(c)) || []),
];

export const allRowsLoaded = (reportRawData: ValidReportRawData) =>
  reportRawData.data.props.total === getNumRows(reportRawData.data);

export const getNumRows = (data: ReportNode<Cell[]>): number =>
  data.children?.reduce<number>((acc, cur) => acc + getNumRows(cur), data.payload ? 1 : 0) || 1;

export const filterNode = (
  data: ReportNode<Cell[]>,
  idsToExclude: number[],
): ReportNode<Cell[]> => ({
  ...data,
  children: data.children
    ?.filter(c => !idsToExclude.includes(c.id))
    .map(c => filterNode(c, idsToExclude)),
});

export const filterNodeCondition = (
  data: ReportNode<Cell[]>,
  includeId: (rowId: number) => boolean,
): ReportNode<Cell[]> => ({
  ...data,
  children: data.children?.filter(c => includeId(c.id)).map(c => filterNodeCondition(c, includeId)),
});

export const getEndOfIncompleteChildList = (
  data: ReportNode<Cell[]>,
  direction: 'last' | 'first',
  expandedRowIds: string[] = [],
): ReportNode<Cell[]> => {
  if (data.children?.length > 0) {
    const limitChild = data.children[direction === 'last' ? data.children.length - 1 : 0];
    const rowIdLimit = direction === 'last' ? data.props?.rowIdStop : data.props?.rowIdStart;

    return rowIdLimit !== limitChild.payload[0][RowField.ROW_HASH] && !limitChild.children?.length
      ? limitChild
      : data.children?.reduce(
          (acc, cur) => acc ?? getEndOfIncompleteChildList(cur, direction, expandedRowIds),
          undefined,
        );
  } else if (
    expandedRowIds.includes(data.payload?.[0][RowField.ROW_HASH]) &&
    data.props?.numChildren > 0
  ) {
    // node is expanded and numChildren is > 0 but no children are present - this is the end.
    return data;
  }
};

export const getNodeById = (data: ReportNode<Cell[]>, rowId: string): ReportNode<Cell[]> =>
  `${data.payload?.[0][0]}` === rowId
    ? data
    : data.children?.map(c => getNodeById(c, rowId)).find(c => c);

const findNode = (
  data: ReportNode<Cell[]>,
  match: (n: ReportNode<Cell[]>) => boolean,
): ReportNode<Cell[]> =>
  match(data) ? data : data.children?.map(c => findNode(c, match)).find(c => c);

export const removeFromEnd = (data: ReportNode<Cell[]>, n: number) =>
  filterNode(
    data,
    getAllRowIndexes(data)
      .slice(-1 * n)
      .map(c => c.index),
  );

const removeFirstLeafNode = (data: ReportNode<Cell[]>) => {
  // WARNING: Mutates function parameter
  // For performance reasons, this function mutates data rather than using functional style.
  if (!data.children[0]?.children?.length) {
    // data.children[0] is a leaf node. Remove it.
    data.children.shift();
  } else {
    // data.children[0] is not a leaf node. Recursively remove the first leaf node in it.
    return removeFirstLeafNode(data.children[0]);
  }
};

export const removeFromBeginning = (
  dataOrig: ReportNode<Cell[]>,
  n: number,
): ReportNode<Cell[]> => {
  // For performance reasons, this function mutates data rather than using functional style.
  const data: ReportNode<Cell[]> = JSON.parse(JSON.stringify(dataOrig));

  for (let i = 0; i < n; i++) {
    removeFirstLeafNode(data);
  }

  return data;
};

export const removeChildRows = (data: ReportNode<Cell[]>, rowHash: string): ReportNode<Cell[]> => ({
  ...data,
  props: {
    ...data.props,
    expandedRowIds:
      data.id === 0 ? data.props.expandedRowIds.filter(e => e !== rowHash) : undefined,
  },
  children:
    data.payload?.[0][RowField.ROW_HASH] === rowHash
      ? undefined
      : data.children?.map(c => removeChildRows(c, rowHash)),
});

export const getRowInfo = (state: AppState, sequenceId: number) => {
  const data = (state.report.reportData[sequenceId]?.raw as ValidReportRawData)?.data;
  const ids = data ? getAllRowIndexes(data).filter(d => d.index !== 0) : [];

  return {
    ...ids.reduce(
      (acc, cur) => ({
        lowest: Math.min(acc.lowest, cur.index),
        highest: Math.max(acc.highest, cur.index),
      }),
      { lowest: Number.MAX_VALUE, highest: Number.MIN_VALUE },
    ),
    n: ids.length,
  };
};

export const getReportDepth = (reportData?: ReportNode<Cell[]>, selectedElements?: string[]) =>
  reportData
    ? Math.max(
        1,
        ...(selectedElements
          ? selectedElements.reduce(
              (acc, curr) => [
                ...acc,
                getNodeById(reportData, curr ? decomposeCellId(curr)?.rowId : null)?.props
                  ?.childDepth,
              ],
              [],
            )
          : [reportData.props?.childDepth]),
      )
    : null;
