// @ts-strict-ignore
import { ReportRequestStatus, ValidReportRawData } from 'algo-react-dataviz';
import { v4 } from 'uuid';
import { reorder } from '../components/designer-panel/drag-and-drop/beautifulDndHelpers';
import {
  ALL_CHECKBOX_SELECTION_ID,
  DESIGNER_SEQUENCE_ID,
  HorizontalPosition,
  RowField,
  VerticalPosition,
} from '../shared/constants';
import {
  Cell,
  DateContext,
  OptionTypes,
  PendingRequest,
  ReportDefinition,
  ReportDefinitionFilter,
  ReportNode,
  ReportRawDataNode,
  ReportState,
  UISlot,
} from '../shared/dataTypes';
import {
  applyReportPrecisionUpdate,
  applyReportUniformStylesUpdate,
  isCharEqualToLayer,
  updateLayersWithCustomGrouping,
} from '../shared/utils';
import * as ActionTypes from './ActionTypes';
import {
  setReportDataFilter,
  setReportDefinitionLevelStyleData,
  setReportDefinitionPrecision,
  setReportDefinitionPrintOptions,
  setReportDefinitionSort,
  setReportDefinitionUniformStyles,
  setReportDisplayFilter,
  updateWithAdHocGrouping,
} from './ReportActionCreators';
import {
  filterNodeCondition,
  merge,
  removeChildRows,
  removeFromBeginning,
  removeFromEnd,
} from './reportUtil';

export const report: (state: ReportState, action) => ReportState = (
  state = {
    reportData: {},
    reportDefinition: {},
    disconnected: false,
  },
  action,
): ReportState => {
  switch (action.type) {
    case ActionTypes.ADD_REQUEST_TIMER: {
      const { sequenceId, requestId, timerId, scrollTop, disableUi } = action.payload;
      return {
        ...state,
        reportData: {
          ...state.reportData,
          [sequenceId]: {
            ...state.reportData[sequenceId],
            pendingRequests: {
              ...state.reportData[sequenceId].pendingRequests,
              [requestId]: {
                ...state.reportData[sequenceId].pendingRequests?.[requestId],
                requestId,
                requestTimestamp: new Date().getTime(),
                timerId,
                scrollTop,
                disableUi,
              },
            },
          },
        },
      };
    }

    case ActionTypes.SET_GLOBAL_OFFLINE_FLAG: {
      if (action.payload.offlineFlag) {
        const reportData = {};
        for (const key in state.reportData) {
          if (state.reportData.hasOwnProperty(key)) {
            reportData[key] = {
              ...state.reportData[key],
              reportStatus: 'Offline',
            };
          }
        }
        return { ...state, reportData: reportData, disconnected: true };
      } else {
        return { ...state, disconnected: false };
      }
    }

    case ActionTypes.SET_REPORT_DEFINITION: {
      const { reportDefinition, sequenceId } = action.payload;

      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [sequenceId]: {
            ...reportDefinition,
          },
        },
      };
    }

    case ActionTypes.SET_COMPLICATION_SELECTION: {
      const { sequenceId, complicationId, selectedOption } = action.payload;

      const slotComplication =
        state.reportDefinition[sequenceId].slotComplications?.find(
          slotComp => complicationId === slotComp.complicationDetails.id,
        ) ||
        state.reportDefinition[sequenceId].menuComplications?.find(
          slotComp => complicationId === slotComp.complicationDetails.id,
        ) ||
        state.reportDefinition[sequenceId].contextMenuComplications?.find(
          slotComp => complicationId === slotComp.complicationDetails.id,
        );

      if (slotComplication) {
        return {
          ...state,
          reportDefinition: {
            ...state.reportDefinition,
            [sequenceId]: {
              ...state.reportDefinition[sequenceId],
              slotComplications: state.reportDefinition[sequenceId].slotComplications?.map(
                slotComp => {
                  if (slotComp.complicationDetails.id === complicationId) {
                    return {
                      ...slotComp,
                      complicationDetails: {
                        ...slotComplication.complicationDetails,
                        selectedOption: selectedOption,
                      },
                    };
                  } else {
                    return slotComp;
                  }
                },
              ),
              menuComplications: state.reportDefinition[sequenceId].menuComplications?.map(
                slotComp => {
                  if (slotComp.complicationDetails.id === complicationId) {
                    return {
                      ...slotComp,
                      complicationDetails: {
                        ...slotComplication.complicationDetails,
                        selectedOption: selectedOption,
                      },
                    };
                  } else {
                    return slotComp;
                  }
                },
              ),
              contextMenuComplications: state.reportDefinition[
                sequenceId
              ].contextMenuComplications?.map(slotComp => {
                if (slotComp.complicationDetails.id === complicationId) {
                  return {
                    ...slotComp,
                    complicationDetails: {
                      ...slotComplication.complicationDetails,
                      selectedOption: selectedOption,
                    },
                  };
                } else {
                  return slotComp;
                }
              }),
            },
          },
        };
      }

      return {
        ...state,
      };
    }

    case ActionTypes.RESET_LAYOUT_COMPLICATION: {
      const sequenceId = action.payload;

      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [sequenceId]: {
            ...state.reportDefinition[sequenceId],
            slotComplications: state.reportDefinition[sequenceId].slotComplications?.filter(
              uiSlot => uiSlot.complicationDetails.type !== 'ChartLayoutComplication',
            ),
          },
        },
      };
    }

    case ActionTypes.SET_LAYOUT_COMPLICATION: {
      const sequenceId = action.payload;

      if (
        state.reportDefinition[sequenceId]?.slotComplications?.some(
          uiSlot => uiSlot.complicationDetails.type === 'ChartLayoutComplication',
        )
      ) {
        return state;
      }

      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [sequenceId]: {
            ...state.reportDefinition[sequenceId],
            slotComplications: [
              ...state.reportDefinition[sequenceId].slotComplications,
              buildLayoutComplication(),
            ],
          },
        },
      };
    }

    case ActionTypes.SET_REPORT_DEFINITION_VISUALIZATIONS: {
      const {
        choicesAndSelection: { options, selectedOption },
        sequenceId,
      } = action.payload;

      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [sequenceId]: {
            ...state.reportDefinition[sequenceId],
            menuComplications: state.reportDefinition[sequenceId].menuComplications.map(slot => {
              if (slot.complicationDetails.type === 'VisualizationComplication') {
                return {
                  ...slot,
                  complicationDetails: {
                    ...slot.complicationDetails,
                    options,
                    selectedOption,
                  },
                };
              } else {
                return slot;
              }
            }),
          },
        },
      };
    }

    case ActionTypes.UPDATE_REPORT_DEFINITION_FILTERS: {
      const { filters, sequenceId } = action.payload;

      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [sequenceId]: {
            ...state.reportDefinition[sequenceId],
            filters,
          },
        },
      };
    }

    case ActionTypes.UPDATE_REPORT_DEFINITION_SCENARIOS_CONFIG_FROM_DRAWER: {
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [action.payload.sequenceId]: {
            ...state.reportDefinition[action.payload.sequenceId],
            scenariosConfig: action.payload.scenariosConfig,
          },
        },
      };
    }

    case ActionTypes.UPDATE_REPORT_DEFINITION_SCENARIOS_CONFIG: {
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            scenariosConfig: action.payload,
          },
        },
      };
    }

    case ActionTypes.UPDATE_REPORT_DEFINITION_SETTINGS: {
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            settings: action.payload,
          },
        },
      };
    }

    case ActionTypes.UPDATE_REPORT_DEFINITION_BENCHMARKS: {
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            benchmarks: action.payload,
          },
        },
      };
    }

    case ActionTypes.UPDATE_REPORT_DEFINITION_PERFORMANCE: {
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            performance: action.payload,
          },
        },
      };
    }

    case ActionTypes.SET_NICKNAME:
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            chars: state.reportDefinition[DESIGNER_SEQUENCE_ID].chars.map(c =>
              c.charId === action.payload.charId && c.modifier === action.payload.modifier
                ? { ...c, nickname: action.payload.nickname }
                : c,
            ),
          },
        },
      };

    case ActionTypes.REPORT_LOADING: {
      const sequenceId: number = action.payload;
      return {
        ...state,
        reportData: {
          ...state.reportData,
          [sequenceId]: { ...state.reportData[sequenceId], raw: { sequenceId, isLoading: true } },
        },
      };
    }
    case ActionTypes.DELETE_REPORT_DATA: {
      // destructure reportData and reportDefinition from the remainingState
      const { reportData, ...intermediateState } = state;
      const { reportDefinition, ...remainingState } = intermediateState;

      // destructure reportData corresponding to the sequenceId (action.payload) of the report being removed
      const { [action.payload]: removedReportData, ...remainingReportData } = reportData;

      // destructure reportDefinition corresponding to the sequenceId (action.payload) of the report being removed
      const {
        [action.payload]: removedReportDefinition,
        ...remainingReportDefinitions
      } = reportDefinition;

      // return updated state just with remaining remainingReportData and remainingReportDefinitions
      return {
        ...remainingState,
        reportData: remainingReportData,
        reportDefinition: remainingReportDefinitions,
      };
    }

    case ActionTypes.REMOVE_PENDING_OPERATION: {
      const { sequenceId, requestId }: { sequenceId: number; requestId: string } = action.payload;

      let animateReport = false;

      // Guard against an end update coming from the server for which there was
      // no matching call to add a pending operation. This could happen as
      // awa updates are not very predictable.
      if (!state.reportData[sequenceId]?.pendingRequests) {
        console.warn(`Response with requestId ${requestId} not found in pending requests`);
        return state;
      }

      // destructure requestId to be removed from the rest of the pending requests
      const { [requestId]: removedRequest, ...remainingPendingRequests } = state.reportData[
        sequenceId
      ]?.pendingRequests;

      // figure out the type of animation needed
      // this code will consolidate multiple requests that result in same highlightMode, that is, if this response corresponds to two or more requests that have highlightMode as REPORT_CHANGE, they all get reduced to just one action of animateReport
      if (removedRequest) {
        // Clear the timer associated with this pending request.
        window.clearTimeout(removedRequest.timerId);

        switch (removedRequest.highlightMode) {
          case 'REPORT_CHANGE': {
            animateReport = true;
            break;
          }
        }
      }

      return {
        ...state,
        reportData: {
          ...state.reportData,
          [sequenceId]: {
            ...state.reportData[sequenceId],
            pendingRequests: remainingPendingRequests,
            pendingRequestsMeta: {
              animateReport,
            },
          },
        },
      };
    }

    case ActionTypes.UPDATE_REPORT_DATA: {
      const { headers } = (state.reportData[action.payload.reportData.sequenceId]?.raw ||
        {}) as ValidReportRawData;

      const payload: ValidReportRawData = {
        ...action.payload.reportData,
        headers:
          headers && action.payload.keepHeaders ? headers : action.payload.reportData.headers,
      };

      const { sequenceId } = payload;

      // If this is a status message update, then only update the reportStatus
      // for the given sequence id. Otherwise, include payload, convertedData
      // and offline.
      return {
        ...state,
        reportData: {
          ...state.reportData,
          [sequenceId]: {
            ...state.reportData[sequenceId],
            raw: payload.statusMessage ? state.reportData[sequenceId].raw : payload,
            updatedAt: new Date().getTime(),
            reportStatus: payload.statusMessage,
          },
        },
      };
    }

    case ActionTypes.UPDATE_REPORT_DATA_NODES: {
      const payload: ReportRawDataNode = action.payload;
      const { sequenceId } = payload;

      let expRows: string[] =
        null === payload?.data?.props?.expandedRowIds ||
        -1 < payload?.data?.props?.expandedRowIds?.length
          ? payload.data.props.expandedRowIds
          : -1 < state.reportDefinition[sequenceId]?.expandedRowIds?.length
          ? state.reportDefinition[sequenceId]?.expandedRowIds
          : null;

      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [sequenceId]: {
            ...state.reportDefinition[sequenceId],
            expandedRowIds: expRows,
          },
        },
        reportData: {
          ...state.reportData,
          [sequenceId]: {
            ...state.reportData[sequenceId],
            raw: {
              ...state.reportData[sequenceId].raw,
              data: merge((state.reportData[sequenceId].raw as ValidReportRawData).data, payload),
            } as ValidReportRawData,
            updatedAt: new Date().getTime(),
            reportStatus: payload.statusMessage,
          },
        },
      };
    }

    case ActionTypes.UPDATE_CELL: {
      const { payload } = action;
      return {
        ...state,
        reportData: {
          ...state.reportData,
          [payload.sequenceId]: {
            ...state.reportData[payload.sequenceId],
            updatedAt: new Date().getTime(),
            raw: {
              ...state.reportData[payload.sequenceId].raw,
              data: updateRawDataChildren(
                (state.reportData[payload.sequenceId].raw as ValidReportRawData).data,
                payload.editDetail.rowIndex + 1,
                payload.editDetail.colIndex,
                payload.editDetail.newValue,
              ),
            } as ValidReportRawData,
          },
        },
      };
    }
    case ActionTypes.UPDATE_REPORT_STATUS: {
      const { requestId, requestStatus, highlightMode, sequenceId, rowId, colId } = action.payload;

      const newPendingRequest: PendingRequest = {
        requestId,
        requestStatus,
        highlightMode,
        requestTimestamp: new Date().getTime(),
        rowId,
        colId,
      };

      // Exclude any pending generate/regenerating/refreshing for
      // the given sequence id since there is now a newer one.
      let newExistingPending: { [requestId: string]: PendingRequest } = {};
      const pendingRequests = removeInitialPendingStatusUpdate(
        state.reportData[sequenceId]?.pendingRequests,
      );

      const clearGeneratingReports = (pendingRequestId: string) => {
        if (
          [
            ReportRequestStatus.RECONNECTING,
            ReportRequestStatus.UPDATING,
            ReportRequestStatus.PENDING,
          ].includes(pendingRequests[pendingRequestId].requestStatus)
        ) {
          newExistingPending[pendingRequestId] = pendingRequests[pendingRequestId];
        } else {
          // Remove the associated timer of the cleared report.
          window.clearTimeout(pendingRequests[pendingRequestId]?.timerId);
        }
      };

      pendingRequests && Object.keys(pendingRequests).forEach(clearGeneratingReports);

      return {
        ...state,
        reportData: {
          ...state.reportData,
          [sequenceId]: {
            ...state.reportData[sequenceId],
            pendingRequests: {
              ...newExistingPending,
              [requestId]: newPendingRequest,
            },
          },
        },
      };
    }
    case ActionTypes.MOVE_PENDING_REQUESTS:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          [action.payload.destinationSequenceId]: {
            ...state.reportData[action.payload.destinationSequenceId],
            pendingRequests: state.reportData[DESIGNER_SEQUENCE_ID].pendingRequests,
          },
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportData[DESIGNER_SEQUENCE_ID],
            pendingRequests: {},
          },
        },
      };
    case ActionTypes.ADD_REPORT_WHAT_IF_PARAMS: {
      const { sequenceId, whatIfParameters } = action.payload;

      return {
        ...state,
        reportData: {
          ...state.reportData,
          [sequenceId]: {
            ...state.reportData[sequenceId],
            whatIfParameters,
          },
        },
      };
    }
    case ActionTypes.REMOVE_DATA_FROM_BEGINNING: {
      return {
        ...state,
        reportData: {
          ...state.reportData,
          [action.payload.sequenceId]: {
            ...state.reportData[action.payload.sequenceId],
            raw: {
              ...state.reportData[action.payload.sequenceId].raw,
              data: removeFromBeginning(
                (state.reportData[action.payload.sequenceId].raw as ValidReportRawData).data,
                action.payload.rowsToRemove,
              ),
            } as ValidReportRawData,
          },
        },
      };
    }

    case ActionTypes.REMOVE_DATA_FROM_END: {
      return {
        ...state,
        reportData: {
          ...state.reportData,
          [action.payload.sequenceId]: {
            ...state.reportData[action.payload.sequenceId],
            raw: {
              ...state.reportData[action.payload.sequenceId].raw,
              data: removeFromEnd(
                (state.reportData[action.payload.sequenceId].raw as ValidReportRawData).data,
                action.payload.rowsToRemove,
              ),
            } as ValidReportRawData,
          },
        },
      };
    }

    case ActionTypes.REMOVE_DATA_AFTER_ROW: {
      return {
        ...state,
        reportData: {
          ...state.reportData,
          [action.payload.sequenceId]: {
            ...state.reportData[action.payload.sequenceId],
            raw: {
              ...state.reportData[action.payload.sequenceId].raw,
              data: filterNodeCondition(
                (state.reportData[action.payload.sequenceId].raw as ValidReportRawData).data,
                rowId => rowId <= action.payload.rowId,
              ),
            } as ValidReportRawData,
          },
        },
      };
    }

    case ActionTypes.REMOVE_CHILDREN: {
      return {
        ...state,
        reportData: {
          ...state.reportData,
          [action.payload.sequenceId]: {
            ...state.reportData[action.payload.sequenceId],
            raw: {
              ...state.reportData[action.payload.sequenceId].raw,
              data: removeChildRows(
                (state.reportData[action.payload.sequenceId].raw as ValidReportRawData).data,
                action.payload.rowId,
              ),
            } as ValidReportRawData,
          },
        },
      };
    }

    case ActionTypes.SET_DX_HIDDEN_COLUMN_NAMES: {
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [action.payload.sequenceId]: {
            ...state.reportDefinition[action.payload.sequenceId],
            hiddenColumnNames: action.payload.hiddenColumnNames,
          },
        },
      };
    }

    case ActionTypes.SET_REPORT_DEFINITION_CHARACTERISTICS: {
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [action.payload.sequenceId]: {
            ...state.reportDefinition[action.payload.sequenceId],
            chars: action.payload.chars,
            verticalChars: action.payload.verticalChars,
            horizontalChars: action.payload.horizontalChars,
          },
        },
      };
    }

    case ActionTypes.SET_REPORTABLE_TYPE: {
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [action.payload.sequenceId]: {
            ...state.reportDefinition[action.payload.sequenceId],
            reportableType: action.payload.reportableType,
          },
        },
      };
    }

    case ActionTypes.SET_REPORT_TITLE: {
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [action.payload.sequenceId]: {
            ...state.reportDefinition[action.payload.sequenceId],
            reportTitle: action.payload.reportTitle,
          },
        },
      };
    }

    case ActionTypes.SET_REPORT_DEFINITION_CURRENCY:
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [action.payload.sequenceId]: {
            ...state.reportDefinition[action.payload.sequenceId],
            currency: action.payload.currency,
          },
        },
      };

    case ActionTypes.SET_SELECTED_GROUPING_LIST_CHAR:
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [action.payload.sequenceId]: {
            ...state.reportDefinition[action.payload.sequenceId],
            selectedGroupingListChar: action.payload.char,
          },
        },
      };

    case ActionTypes.SET_REPORT_DATA_FILTER:
      const { sequenceId, dataFilter } = (action as ReturnType<typeof setReportDataFilter>).payload;

      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [sequenceId]: {
            ...state.reportDefinition[sequenceId],
            dataFilter,
          },
        },
      };

    case ActionTypes.SET_REPORT_DISPLAY_FILTER:
      const { char, filter } = (action as ReturnType<typeof setReportDisplayFilter>).payload;
      const vertChars = state.reportDefinition[DESIGNER_SEQUENCE_ID].verticalChars;
      const newVertChars = vertChars.map(vertChar => {
        if (isCharEqualToLayer(char, vertChar)) {
          return {
            ...vertChar,
            displayFilter: filter,
          };
        } else return vertChar;
      });

      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            verticalChars: newVertChars,
          },
        },
      };
    case ActionTypes.TOGGLE_DESIGNER_DATE_CONTEXT_RUN:
      const oldDateContext = state.reportDefinition[DESIGNER_SEQUENCE_ID].dateContext;
      let newDateContext: DateContext;

      switch (oldDateContext.type) {
        case 'asOf':
          newDateContext = {
            type: 'asOf',
            dates: [
              { date: action.payload.date || oldDateContext.dates[0].date, id: action.payload.id },
            ],
          };
          break;
        case 'specific':
          newDateContext = {
            type: oldDateContext.type,
            dates: oldDateContext.dates.some(
              o => o.id === action.payload.id && o.date === action.payload.date,
            )
              ? oldDateContext.dates.filter(
                  o => o.id !== action.payload.id && o.date !== action.payload.date,
                )
              : oldDateContext.dates.concat({ id: action.payload.id, date: action.payload.date }),
          };
          break;
        default:
          newDateContext = oldDateContext; // no choosing runs for default or between
      }

      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            dateContext: newDateContext,
          },
        },
      };

    case ActionTypes.SET_REPORT_DEFINITION_DATE_CONTEXT:
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [action.payload.sequenceId]: {
            ...state.reportDefinition[action.payload.sequenceId],
            dateContext: action.payload.dateContext,
          },
        },
      };

    case ActionTypes.SET_REPORT_DEFINITION_SORT: {
      const {
        payload: { sequenceId, sort },
      }: ReturnType<typeof setReportDefinitionSort> = action;

      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [sequenceId]: {
            ...state.reportDefinition[sequenceId],
            sort,
          },
        },
      };
    }

    case ActionTypes.SET_REPORT_DEFINITION_PRECISION: {
      const {
        payload: { sequenceId, update },
      }: ReturnType<typeof setReportDefinitionPrecision> = action;

      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [sequenceId]: applyReportPrecisionUpdate(state.reportDefinition[sequenceId], update),
        },
      };
    }

    case ActionTypes.SET_REPORT_DEFINITION_STYLES: {
      const {
        payload: { sequenceId, styles },
      }: ReturnType<typeof setReportDefinitionUniformStyles> = action;

      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [sequenceId]: applyReportUniformStylesUpdate(state.reportDefinition[sequenceId], styles),
        },
      };
    }

    case ActionTypes.SET_REPORT_DEFINITION_LEVEL_STYLE_DATA: {
      const {
        payload: { sequenceId, levelStyleData },
      }: ReturnType<typeof setReportDefinitionLevelStyleData> = action;

      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [sequenceId]: {
            ...state.reportDefinition[sequenceId],
            levelStyleData,
          },
        },
      };
    }

    case ActionTypes.SET_REPORT_DEFINITION_PRINT_OPTIONS: {
      const {
        payload: { sequenceId, printOptions },
      }: ReturnType<typeof setReportDefinitionPrintOptions> = action;

      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [sequenceId]: {
            ...state.reportDefinition[sequenceId],
            printOptions,
          },
        },
      };
    }

    case ActionTypes.UPDATE_REPORT_PATHS:
      return {
        ...state,
        reportDefinition: Object.keys(state.reportDefinition)
          .map(k => {
            const def: ReportDefinition & { key: string } = {
              ...state.reportDefinition[k],
              key: k,
            };
            return def.path?.startsWith(action.payload.oldPath)
              ? { ...def, path: def.path.replace(action.payload.oldPath, action.payload.newPath) }
              : def;
          })
          .reduce((acc, cur) => ({ ...acc, [cur.key]: cur }), {}),
      };
    case ActionTypes.IMPORT_REPORT_DEFINITIONS:
      return {
        ...state,
        reportDefinition: action.payload,
      };

    case ActionTypes.SET_REPORT_PORTFOLIO_NODES:
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [action.payload.sequenceId]: {
            ...state.reportDefinition[action.payload.sequenceId],
            selectedPortfolioNodeIds: action.payload.nodes.selectedPortfolioNodeIds,
            selectedAdHocPortfolioNames: action.payload.nodes.selectedAdHocPortfolioNames,
            selectedBenchmarkPortfolioNames: action.payload.nodes.selectedBenchmarkPortfolioNames,
            selectedPortfolioHierarchyName: action.payload.nodes.selectedPortfolioHierarchyName,
          },
        },
      };

    case ActionTypes.REMOVE_REPORT_PORTFOLIO_NODES:
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [action.payload.sequenceId]: {
            ...state.reportDefinition[action.payload.sequenceId],
            selectedPortfolioNodeIds: undefined,
            selectedAdHocPortfolioNames: undefined,
            selectedBenchmarkPortfolioNames: undefined,
            selectedPortfolioHierarchyName: undefined,
            useDefinitionHierarchy: undefined,
          },
        },
      };

    case ActionTypes.REMOVE_SELECTED_REPORT_ENTITIES:
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [action.payload.sequenceId]: {
            ...state.reportDefinition[action.payload.sequenceId],
            selectedEntities: undefined,
          },
        },
      };

    case ActionTypes.SET_SELECTED_REPORT_ENTITIES:
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [action.payload.sequenceId]: {
            ...state.reportDefinition[action.payload.sequenceId],
            selectedEntities: action.payload.selectedEntities,
          },
        },
      };

    case ActionTypes.UPDATE_WITH_AD_HOC_GROUPING:
      const {
        payload: { customGrouping, removePrevious, direction },
      }: ReturnType<typeof updateWithAdHocGrouping> = action;
      const key = direction === 'horizontal' ? 'horizontalChars' : 'verticalChars';

      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            [key]: updateLayersWithCustomGrouping(
              state.reportDefinition[DESIGNER_SEQUENCE_ID][key],
              customGrouping,
              removePrevious,
            ),
          },
        },
      };

    case ActionTypes.ADD_FILTER:
      let newState: ReportDefinitionFilter[] = state.reportDefinition[
        DESIGNER_SEQUENCE_ID
      ]?.filters.slice();
      newState.splice(action.payload.endIndex, 0, {
        charId: action.payload.char.charId,
        type: 'EQUALS',
      });
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            filters: newState,
          },
        },
      };
    case ActionTypes.REMOVE_FILTER:
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            filters: state.reportDefinition[DESIGNER_SEQUENCE_ID].filters.filter(
              e => e.charId !== action.payload,
            ),
          },
        },
      };
    case ActionTypes.REMOVE_ALL_FILTERS:
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            filters: [],
          },
        },
      };
    case ActionTypes.REORDER_FILTER:
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            filters: reorder(
              state.reportDefinition[DESIGNER_SEQUENCE_ID].filters,
              action.payload.startIndex,
              action.payload.endIndex,
            ),
          },
        },
      };

    case ActionTypes.UPDATE_FILTER_TYPE:
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            filters: state.reportDefinition[DESIGNER_SEQUENCE_ID].filters.map(e =>
              e.charId === action.payload.charId
                ? {
                    ...e,
                    type: action.payload.filterType,
                    selectedOptions: undefined,
                    null: action.payload.filterType === 'LIST',
                  }
                : e,
            ),
          },
        },
      };

    case ActionTypes.SET_SELECTED_FILTER_OPTIONS:
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            filters: state.reportDefinition[DESIGNER_SEQUENCE_ID].filters.map(e =>
              e.charId === action.payload.charId
                ? {
                    ...e,
                    selectedOptions: action.payload.options.filter(
                      (o: OptionTypes) => o !== ALL_CHECKBOX_SELECTION_ID,
                    ),
                  }
                : e,
            ),
          },
        },
      };

    case ActionTypes.SET_NOT:
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            filters: state.reportDefinition[DESIGNER_SEQUENCE_ID].filters.map(e =>
              e.charId === action.payload.charId ? { ...e, not: action.payload.value } : e,
            ),
          },
        },
      };

    case ActionTypes.SET_NULL:
      return {
        ...state,
        reportDefinition: {
          ...state.reportDefinition,
          [DESIGNER_SEQUENCE_ID]: {
            ...state.reportDefinition[DESIGNER_SEQUENCE_ID],
            filters: state.reportDefinition[DESIGNER_SEQUENCE_ID].filters.map(e =>
              e.charId === action.payload.charId ? { ...e, null: action.payload.value } : e,
            ),
          },
        },
      };

    // Add cases here for updating characteristic based values (chars, horz, vert)
    default:
      return state;
  }
};

const buildLayoutComplication = (): UISlot => ({
  complicationDetails: {
    type: 'ChartLayoutComplication',
    callbackIdentifier: 'chartLayout',
    id: v4(),
    textBefore: 'Transpose:',
    textAfter: '',
    highlightMode: 'NONE',
    options: { HORIZONTAL: 'Horizontal', VERTICAL: 'Vertical' },
    selectedOption: 'HORIZONTAL',
    editRequiresServer: false,
  },
  controlType: 'SINGLE_DROPDOWN',
  control2ndType: 'TEXT',
  horizontalPosition: HorizontalPosition.LEFT,
  verticalPosition: VerticalPosition.BOTTOM,
  enabled: true,
});

const updateRawDataChildren = (
  node: ReportNode<Cell[]>,
  rowId: number,
  colIndex: number,
  newValue: any,
): ReportNode<Cell[]> => {
  if (node.id === rowId) {
    return {
      ...node,
      payload: node.payload.map((cell, cellColIndex) =>
        cellColIndex === colIndex
          ? { ...cell, [RowField.VAL]: newValue, [RowField.FORMATTED_VAL]: newValue }
          : cell,
      ),
    };
  } else if (node.children) {
    return {
      ...node,
      children: node.children.map(child => updateRawDataChildren(child, rowId, colIndex, newValue)),
    };
  } else {
    return node;
  }
};

const removeInitialPendingStatusUpdate = pendingRequests => {
  if (pendingRequests) {
    const { InitialPendingStatus: removedRequest, ...remainingPendingRequests } = pendingRequests;

    return remainingPendingRequests;
  }
  return null;
};
