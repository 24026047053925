// @ts-strict-ignore
import deepEqual from 'deep-equal';
import { GroupingLayerId } from '../../components/designer-panel/drag-and-drop/groupingLayerId';
import { DESIGNER_SEQUENCE_ID } from '../../shared/constants';
import { Characteristic } from '../../shared/dataTypes';
import { AppState, AppThunk } from '../configureStore';
import { setSelectedGroupingListChar } from '../ReportActionCreators';
import { GroupingList } from './reducer';

export const getNewGroupingLists = (state: AppState) =>
  state.groupingLists.unsavedGroupingLists.filter(
    u => !state.groupingLists.savedGroupingLists.some(s => s.id === u.id),
  );

export const getChangedGroupingLists = (state: AppState) =>
  state.groupingLists.unsavedGroupingLists.filter(unsaved => {
    const saved = state.groupingLists.savedGroupingLists.find(s => s.id === unsaved.id);
    return !!saved && !groupingListsEqual(unsaved, saved);
  });

export const groupingListsHaveUnsavedChanges = (state: AppState) =>
  getNewGroupingLists(state).length > 0 || getChangedGroupingLists(state).length > 0;

export const getDeletedGroupingLists = (state: AppState) =>
  state.groupingLists.savedGroupingLists.filter(
    s => !state.groupingLists.unsavedGroupingLists.some(u => u.id === s.id),
  );

const isCharIdEqual = (a: Characteristic, b: Characteristic) =>
  (a.charId === GroupingLayerId.CUSTOM_GROUPING ? a.id : a.charId) ===
  (b.charId === GroupingLayerId.CUSTOM_GROUPING ? b.id : b.charId);

const groupingListsEqual = (listA: GroupingList, listB: GroupingList) =>
  listA.id === listB.id &&
  listA.name === listB.name &&
  listA.chars.length === listB.chars.length &&
  listA.chars.every(
    (a, i) =>
      isCharIdEqual(a, listB.chars[i]) &&
      breakpointsEqual(a.breakpoints, listB.chars[i]?.breakpoints) &&
      deepEqual(a.grouperData, listB.chars[i]?.grouperData),
  );

const breakpointsEqual = (breakpointsA?: string[], breakpointsB?: string[]) =>
  (!breakpointsA && !breakpointsB) ||
  (breakpointsA?.length === breakpointsB?.length &&
    breakpointsA?.every((a, i) => a === breakpointsB?.[i]));

export const getGroupingListForReport = (sequenceId: number) => (state: AppState) => {
  const { verticalChars } = state.report.reportDefinition[sequenceId];

  return verticalChars?.length === 1 && getGroupingList(verticalChars[0].groupingListId)(state);
};

export const getGroupingList = (groupingListId: string) => (state: AppState) =>
  state.groupingLists.savedGroupingLists.find(list => list.id === groupingListId);

export const getSelectedGroupingListChar = (reportId: number) => (state: AppState) =>
  state.report.reportDefinition[reportId]?.selectedGroupingListChar;

export const setDefaultDesignerGroupingListChar = (groupingListId: string): AppThunk => (
  dispatch,
  getState,
) => {
  const defaultChar: Characteristic = getGroupingList(groupingListId)(getState())?.chars?.[0];
  const groupingChar =
    defaultChar.charId === GroupingLayerId.CUSTOM_GROUPING
      ? {
          charId: GroupingLayerId.CUSTOM_GROUPING,
          customGroupingId: defaultChar.id,
        }
      : {
          charId: defaultChar.charId,
        };
  dispatch(setSelectedGroupingListChar(DESIGNER_SEQUENCE_ID, groupingChar));
};
