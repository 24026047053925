// @ts-strict-ignore
import { Modifier, NodeType, RowField, XYChartType } from 'algo-react-dataviz';
import moment from 'moment';
import { CSSProperties } from 'react';
import { v4 } from 'uuid';
import { GroupingLayerId } from '../components/designer-panel/drag-and-drop/groupingLayerId';
import {
  GetEntityHierarchyGrouperData,
  GetProductSetHierarchyGrouperData,
  GetTakerGrouperData,
} from '../components/designer-panel/grouperDataUtils';
import { createDefaultSort } from '../model/metadata/sorting';
import { createDefaultConfig } from '../model/scenariosConfig';
import { createDefaultSettings } from '../model/settings';
import { Benchmark, RecentWorkspace, ReportDefinition, UserInfo, UserState } from './dataTypes';

// This file is for constants - things that exist at runtime. Types and interfaces should go in
// dataTypes.ts.

export { NodeType, RowField, XYChartType };

export enum HorizontalPosition {
  CONTEXT_MENU = 'CONTEXT_MENU',
  MENU = 'MENU',
  FLOATING = 'FLOATING',
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

export enum VerticalPosition {
  CONTEXT_MENU = 'CONTEXT_MENU',
  MENU = 'MENU',
  FLOATING = 'FLOATING',
  TOP = 'TOP',
  MIDDLE = 'MIDDLE',
  BOTTOM = 'BOTTOM',
}

export enum DrawerType {
  OPEN_REPORT_IN_WORKSPACE,
  OPEN_REPORT_IN_DESIGNER,
  OPEN_REPORT_TEMPLATE_IN_WORKSPACE,
  REPLACE_REPORT,
  OPEN_WORKSPACE,
  OPEN_WORKSPACE_TEMPLATE,
  APPEND_WORKSPACE,
  SAVE_REPORT,
  SAVE_WORKSPACE,
  IMPORT_REPORT_TO_FOLDER,
  IMPORT_WORKSPACE_TO_FOLDER,
  EXPORT_REPORTS,
  EXPORT_WORKSPACES,
  SELECT_DEFAULT_WORKSPACE,
}

export const XYChartName2Type = {
  XY_LINE_CHART: XYChartType.XY_LINE,
  XY_LINE_NO_SYMBOL_CHART: XYChartType.XY_LINE_ONLY,
  XY_SYMBOL_NO_LINE_CHART: XYChartType.XY_DOTS_ONLY,
  XY_STACKED_BAR_CHART: XYChartType.XY_STACKED_BAR,
  XY_SPLINE_LINE_CHART: XYChartType.XY_SPLINE_LINE,
  XY_SPLINE_LINE_ONLY_CHART: XYChartType.XY_SPLINE_LINE_ONLY,
  XY_BAR_CHART: XYChartType.XY_BAR,
};

export enum NotificationLevel {
  INFO = 'info',
  WARN = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const CustomGrouperDataImpls = {
  TakerHierarchyGrouper: GetTakerGrouperData,
  ProductSetHierarchyGrouper: GetProductSetHierarchyGrouperData,
  EntityHierarchyGrouper: GetEntityHierarchyGrouperData,
};

export const VisualizationFriendlyNames = {
  DX_TABLE: 'Tabular',
  // MAP_CHART: 'Geo',
  BAR_CHART: 'Bar',
  STACKED_BAR_CHART: 'Stacked Bar',
  MIXED_BAR_CHART: 'Mixed Bar',
  BRUSH_BAR_CHART: 'Brush Bar',
  STACKED_BRUSH_BAR_CHART: 'Stacked Brush Bar',
  LINE_GRAPH: 'Line',
  XY_LINE_CHART: 'XY Line',
  PIE_CHART: 'Pie',
  AREA_CHART: 'Area',
  STACKED_AREA_CHART: 'Stacked Area',
  XY_AREA_CHART: 'XY Area',
  ACTIVE_PIE_CHART: 'Active Pie',
  TWO_LEVEL_PIE_CHART: '2Level Pie',
  TREE_MAP: 'Tree Map',
  SCATTER_CHART: 'Scatter Plot',
  BUBBLE_CHART: 'Bubble Plot',
  COMPOSED_CHART: 'Composed',
  RADIAL_BAR_CHART: 'Radial Bar',
  BULLET_GRAPH: 'Bullet',
  XY_LINE_NO_SYMBOL_CHART: 'XY Line Only',
  XY_STACKED_BAR_CHART: 'XY Stacked Bar Chart',
  XY_BAR_CHART: 'XY Bar Chart',
  XY_SPLINE_LINE_CHART: 'XY Spline Line Chart',
  XY_SPLINE_LINE_ONLY_CHART: 'XY Spline Line Only Chart',
};

export enum DataType {
  STRING = 'STRING',
  NUMBER = 'NUMBER', //Whole Number
  DECIMAL = 'DECIMAL', // Whole or Decimal
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  OTHER = 'OTHER',
}

export const ADHOC_REPORT_FOLDER = '@@adhoc_reports@@';

export const defaultReportDesignerBenchmarks: [Benchmark, Benchmark] = [
  {
    dataSetType: 'hierarchy',
    reportDateType: 'same',
    sandboxType: 'sameSandbox',
    dataSetPortfolio: '',
    relativeDate: '',
    dateContextOption: { date: '', id: '' },
    sandbox: '',
    individualBenchmarks: [],
    adHocBenchmarks: [],
  },
  {
    dataSetType: 'hierarchy',
    reportDateType: 'same',
    sandboxType: 'sameSandbox',
    dataSetPortfolio: '',
    relativeDate: '',
    dateContextOption: { date: '', id: '' },
    sandbox: '',
    individualBenchmarks: [],
    adHocBenchmarks: [],
  },
];

const defaultSlotComplications = [];
const defaultMenuComplications = [
  {
    complicationDetails: {
      id: v4(),
      type: 'VisualizationComplication',
      textBefore: 'Visualization Type',
      textAfter: '',
      highlightMode: 'NONE',
      options: [
        'DX_TABLE',
        'BAR_CHART',
        'STACKED_BAR_CHART',
        'MIXED_BAR_CHART',
        'BRUSH_BAR_CHART',
        'STACKED_BRUSH_BAR_CHART',
        'LINE_GRAPH',
        'XY_LINE_CHART',
        'PIE_CHART',
        'AREA_CHART',
        'STACKED_AREA_CHART',
        'XY_AREA_CHART',
        'ACTIVE_PIE_CHART',
        'TWO_LEVEL_PIE_CHART',
        'COMPOSED_CHART',
        'RADIAL_BAR_CHART',
        // 'MAP_CHART',
        'TREE_MAP',
        'BUBBLE_CHART',
        'SCATTER_CHART',
        // 'BULLET_GRAPH',
      ],
      selectedOption: 'DX_TABLE',
      callbackIdentifier: 'visualization',
      editRequiresServer: false,
    },
    horizontalPosition: HorizontalPosition.MENU,
    verticalPosition: VerticalPosition.MENU,
    controlType: 'SINGLE_DROPDOWN',
    control2ndType: 'TEXT',
    enabled: true,
  },
];

const defaultContextMenuComplications = [
  // Post MVP we may return to BenchAgainstComplication but
  // it's not ready just yet.
  // {
  //   complicationDetails: {
  //     type: 'BenchAgainstComplication',
  //     textBefore: 'Bench Against',
  //     textAfter: '',
  //     highlightMode: 'REPORT_CHANGE',
  //     options: { '-1': 'Prior Day', '-30': 'Prior Month', '-365': 'Prior Year' },
  //     callbackIdentifier: 'benchAgainst',
  //   },
  //   controlType: 'SINGLE_DROPDOWN',
  //   control2ndType: 'TEXT',
  //   horizontalPosition: HorizontalPosition.CONTEXT_MENU,
  //   verticalPosition: VerticalPosition.CONTEXT_MENU,
  //   enabled: true,
  // },
];
export const EMPTY_REPORT_TITLE = 'Untitled';

export enum PerformanceReportableTypes {
  POSITION = 22,
  // TODO add TRANSACTION when it exists
}

export const defaultReportDefinition: ReportDefinition = {
  reportableType: PerformanceReportableTypes.POSITION,
  hiddenColumnNames: [],
  chars: [],
  verticalChars: [],
  horizontalChars: [{ layerId: -3, linkedGroupingPath: null, modifier: Modifier.PORT }],
  reportTitle: EMPTY_REPORT_TITLE,
  filters: [],
  scenariosConfig: createDefaultConfig(),
  settings: createDefaultSettings(),
  benchmarks: defaultReportDesignerBenchmarks,
  asOfDate: '',
  slotComplications: defaultSlotComplications,
  menuComplications: defaultMenuComplications,
  contextMenuComplications: defaultContextMenuComplications,
  dateContext: { type: 'default' },
  currency: 'default',
  sort: undefined,
  columnWidths: [],
  expandedRowIds: null,
  frozenColumns: [],
};

export const defaultReportDefinitionSaccr: ReportDefinition = {
  ...defaultReportDefinition,
  reportableType: 121, // SACCR Deals
};

export const defaultReportDefinitionAcm: ReportDefinition = {
  ...defaultReportDefinition,
  reportableType: 21, // ACM Entities
};

export const defaultMetadataReportDefinition: ReportDefinition = {
  ...defaultReportDefinition,
  reportableType: 11,
  verticalChars: [
    { layerId: GroupingLayerId.DETAIL, modifier: Modifier.PORT, linkedGroupingPath: undefined },
  ],
  sort: createDefaultSort(),
  filters: [{ charId: 1020, not: true, selectedOptions: [true], type: 'EQUALS' }], // Equivalent to ValueKey.COLDEF_IsCore_KEY is not true as defined in iwa
};

export const createDefaultUserState = (): UserState => ({
  loggedIn: false,
  sessionExpiredWarningToggle: false,
  tk: null,
  username: null,
  hasUserInfo: false,
  userInfo: createDefaultUserInfo(),
  userComponentPermission: {
    allowAdmin: false,
    allowAscript: false,
    allowManageAdhoc: false,
    allowViewMetadata: false,
    allowMetadata: false,
    allowLegacyReports: false,
    allowCreateFolderUnderRoot: false,
    allowRemoteConsole: false,
    allowModifyEditableCharacterstics: false,
  },
  refreshTimeoutId: null,
});

export const createDefaultUserInfo = (): UserInfo => ({
  username: null,
  firstname: null,
  lastname: null,
  serverConfigs: null,
  userPreferences: null,
  recentWorkspaces: [],
});

export const createRecentWorkspace = (name: string): RecentWorkspace => ({
  name,
  date: moment().format('YYYY-MM-DD'),
});

export enum FolderType {
  WORKSPACE = 'WORKSPACE',
  REPORT = 'REPORT',
  SANDBOX = 'SANDBOX',
}

export enum AccessType {
  READ = 'READ',
  WRITE = 'WRITE',
}
export const CHARACTERISTIC_CHAR_ID = -3;
export const ALL_CHECKBOX_SELECTION_ID = 'ALL_CHECKBOX_SELECTION_ID';
export const NULLS_CHECKBOX_SELECTION_ID = 'NULLS_CHECKBOX_SELECTION_ID';

export const DESIGNER_SEQUENCE_ID = -1;
export const METADATA_SEQUENCE_ID = -2;

export const legendWrapperStyles: { horizontal: CSSProperties; vertical: CSSProperties } = {
  horizontal: { maxWidth: '100%', overflow: 'auto', height: 'auto', whiteSpace: 'nowrap' },
  vertical: { maxHeight: '100%', overflow: 'auto', right: '-6px' },
};

export enum CharacteristicDrawerType {
  EDIT,
  ADD,
  COPY,
}

export const INITIAL_PENDING_STATUS = 'InitialPendingStatus';

export const DRILLTHROUGH_SETTING_TOOLTIP = 'Inherited from parent report';

export const DEFAULT_PORTFOLIO_HIERARCHY_NAME = 'Default';

export const MAX_NODES_BLOCK_SIZE = 2147483647;

const sharedSearchKeys = [
  'integratedrisk.common.model.DataFields.INSTRUMENT_NAME',
  'integratedrisk.common.model.DataFields.INSTRUMENT_ID',
  'integratedrisk.common.model.DataFields.INSTRUMENT_CUSIP',
  'integratedrisk.common.model.DataFields.INSTRUMENT_ISIN',
];

export const instrumentSearchKeys = [
  ...sharedSearchKeys,
  'integratedrisk.common.model.DataFields.HASHBUCKET_PORTFOLIO_NAME',
];

export const positionSearchKeys = [
  ...sharedSearchKeys,
  'integratedrisk.common.model.DataFields.POSITION_NAME_FORMATTED',
];
