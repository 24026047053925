import axios from 'axios';
import { ContentPage } from '../../components/main/PageContent/contentPage';
import { defaultMetadataChars } from '../../components/metadata/characteristic-drawer/characteristicDrawerFormHelpers';
import { baseUrl } from '../../components/shared/environment';
import { StyleProps } from '../../model/styles/styleProps';
import { StyleRule } from '../../model/styles/styleRule';
import { StyleTarget, StyleTargetType } from '../../model/styles/styleTarget';
import { DESIGNER_SEQUENCE_ID, DrawerType, NotificationLevel } from '../../shared/constants';
import { hasOpenWorkspace } from '../../shared/utils';
import {
  enqueueSnackbar,
  generateMetadataReport,
  getErrorMessage,
  openFolderDrawer,
  setOriginalDetachedReport,
} from '../ActionCreators';
import * as ActionTypes from '../ActionTypes';
import { openNewReport } from '../ReportActionCreators';
import { clearWorkspace, removeReportFromWorkspace } from '../WorkspaceActionCreators';
import { getAllUsersAndGroups } from '../adminActionCreators';
import { AppThunk } from '../configureStore';
import { groupingListsHaveUnsavedChanges } from '../grouping-lists/selectors';
import { isDesignerDirty } from '../report/selectors';
import { ChangeWorkspaceModalStatus } from './changeWorkspaceModalStatus';
import { OverwriteConfirmationStatus } from './overwriteConfirmationStatus';
import { getActivePage, isDataSettingsOpen, isDesignerOpen } from './selectors';
import { DEFAULT_CONTENT_PAGE } from './state';
import { LevelStyleData } from '../../shared/dataTypes';
/**
 * Page navigation
 */

export const setActivePage = (page: ContentPage) =>
  ({
    type: ActionTypes.SET_ACTIVE_PAGE,
    payload: page,
  } as const);

export const openPage = (page: ContentPage): AppThunk => (dispatch, getState) => {
  if (getActivePage(getState()) === page) {
    return;
  }

  if (page === ContentPage.ADMIN) {
    dispatch(getAllUsersAndGroups(true));
  }

  if (page === ContentPage.DATA_SETTINGS) {
    dispatch(
      generateMetadataReport(
        getState().user.userInfo.userPreferences?.selectedMetadataViewerFields ||
          defaultMetadataChars,
      ),
    );
  }

  dispatch(setActivePage(page));
};

export const openHomePage = () => openPage(ContentPage.HOME);
export const openWorkspacePage = () => openPage(ContentPage.WORKSPACE);
export const openDesignerPage = () => openPage(ContentPage.DESIGNER);
export const openAdminPage = () => openPage(ContentPage.ADMIN);
export const openMetadataPage = () => openPage(ContentPage.DATA_SETTINGS);

export const closePage = (page: ContentPage, nextPage = DEFAULT_CONTENT_PAGE): AppThunk => (
  dispatch,
  getState,
) => {
  if (getActivePage(getState()) === page) {
    if (nextPage === ContentPage.DESIGNER) {
      dispatch(openNewReport(false));
    }
    dispatch(openPage(nextPage));
  }
};

export const closeDesigner = (nextPage = DEFAULT_CONTENT_PAGE) =>
  closePage(ContentPage.DESIGNER, nextPage);

/**
 * <ChangeWorkspaceModal /> actions
 */

export const setChangeWorkspaceModalStatus = (status: ChangeWorkspaceModalStatus | null) =>
  ({
    type: ActionTypes.SET_CHANGE_WORKSPACE_MODAL_STATUS,
    payload: status,
  } as const);

export const _setDisplayFunds = (displayFunds: boolean) =>
  ({
    type: ActionTypes.SET_DISPLAY_FUNDS,
    payload: displayFunds,
  } as const);

export const setDisplayFunds = (displayFunds: boolean): AppThunk => dispatch => {
  dispatch(_setDisplayFunds(displayFunds));
  axios
    .post(`${baseUrl}api/updateDisplayFunds`, null, {
      params: { displayFunds },
    })
    .catch(error => {
      dispatch(_setDisplayFunds(displayFunds));
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Unable to set display funds: ${getErrorMessage(error)}`,
        ),
      );
    });
};

export const setOverwriteConfirmationModalStatus = (status: OverwriteConfirmationStatus | null) =>
  ({
    type: ActionTypes.SET_OVERWRITE_CONFIRMATION_MODAL_STATUS,
    payload: status,
  } as const);

export const intendChangeWorkspace = (status: ChangeWorkspaceModalStatus): AppThunk => (
  dispatch,
  getState,
) => {
  // If there is no open workspace, we don't need to
  // prompt the user for confirmation.
  if (hasOpenWorkspace(getState())) {
    dispatch(setChangeWorkspaceModalStatus(status));
  } else {
    dispatch(confirmChangeWorkspace(status));
  }
};

export const confirmChangeWorkspace = (status?: ChangeWorkspaceModalStatus): AppThunk => (
  dispatch,
  getState,
) => {
  switch (status ?? getState().ui.changeWorkspaceModalStatus) {
    case ChangeWorkspaceModalStatus.CREATE_BLANK: {
      dispatch(confirmClearWorkspace());
      break;
    }

    case ChangeWorkspaceModalStatus.CREATE_FROM_TEMPLATE: {
      dispatch(openFolderDrawer(DrawerType.OPEN_WORKSPACE_TEMPLATE));
      break;
    }

    case ChangeWorkspaceModalStatus.OPEN: {
      dispatch(openFolderDrawer(DrawerType.OPEN_WORKSPACE));
      break;
    }

    default: {
      break;
    }
  }

  dispatch(closeChangeWorkspaceModal());
};

export const confirmClearWorkspace = (): AppThunk => dispatch => {
  dispatch(clearWorkspace());
  dispatch(openWorkspacePage());
};

export const closeChangeWorkspaceModal = () => setChangeWorkspaceModalStatus(null);

/**
 * <CloseDesignerModal /> actions
 */

export const intendCloseDesigner = (page: ContentPage) =>
  ({
    type: ActionTypes.INTEND_CLOSE_DESIGNER,
    payload: page,
  } as const);

export const promptCloseDesigner = (intendedPage?: ContentPage): AppThunk => (
  dispatch,
  getState,
) => {
  if (!intendedPage) intendedPage = getState().ui.previousPage || DEFAULT_CONTENT_PAGE;
  if (isDesignerOpen(getState())) {
    if (isDesignerDirty(getState())) {
      dispatch(intendCloseDesigner(intendedPage));
    } else {
      dispatch(setOriginalDetachedReport());
      dispatch(openPage(intendedPage));
      dispatch(removeReportFromWorkspace(DESIGNER_SEQUENCE_ID));
    }
  }
};

export const confirmCloseDesigner = () =>
  ({
    type: ActionTypes.CONFIRM_CLOSE_DESIGNER,
  } as const);

export const cancelCloseDesigner = () =>
  ({
    type: ActionTypes.CANCEL_CLOSE_DESIGNER,
  } as const);

export const intendCloseDataSettings = (page: ContentPage) =>
  ({ type: ActionTypes.INTEND_CLOSE_DATA_SETTINGS, payload: page } as const);

export const promptCloseDataSettings = (intendedPage?: ContentPage): AppThunk => (
  dispatch,
  getState,
) => {
  if (!intendedPage) intendedPage = getState().ui.previousPage || DEFAULT_CONTENT_PAGE;
  if (isDataSettingsOpen(getState())) {
    if (groupingListsHaveUnsavedChanges(getState())) {
      dispatch(intendCloseDataSettings(intendedPage));
    } else {
      dispatch(closePage(ContentPage.DATA_SETTINGS, intendedPage));
    }
  }
};

export const closeDataSettingsModal = () =>
  ({ type: ActionTypes.CLOSE_DATA_SETTINGS_MODAL } as const);

export const intendCloseGroupingListEditor = () =>
  ({ type: ActionTypes.INTEND_CLOSE_GROUPING_LIST_EDITOR } as const);

export type StyleEditorMode = 'UNIFORM' | 'LEVEL_EDIT' | 'CONDITIONAL' | 'CONDITIONAL_EDIT';
export interface OpenUniformStyleModalProps {
  sequenceId: number;
  targetType: StyleTargetType | null;
  targets: StyleTarget[] | null;
  editorType: StyleEditorMode;
  styleId?: string | null;
  levelToEdit?: number | null;
}

export const openReportStylesModal = ({
  sequenceId,
  targetType,
  targets,
  editorType,
  styleId,
  levelToEdit,
}: OpenUniformStyleModalProps) =>
  ({
    type: ActionTypes.OPEN_REPORT_STYLES_MODAL,
    payload: {
      sequenceId,
      targetType,
      targets,
      editorType,
      styleId,
      levelToEdit,
    },
  } as const);

export const closeReportStylesModal = () =>
  ({
    type: ActionTypes.CLOSE_REPORT_STYLES_MODAL,
  } as const);

export const setCopiedStyleProps = (payload: StyleProps | null) =>
  ({
    type: ActionTypes.SET_COPIED_STYLE_PROPS,
    payload,
  } as const);

export const setIsAboutOpen = (payload: boolean) =>
  ({
    type: ActionTypes.SET_IS_ABOUT_OPEN,
    payload,
  } as const);

export const setIsRemoteConsoleOpen = (payload: boolean) =>
  ({
    type: ActionTypes.SET_IS_REMOTE_CONSOLE_OPEN,
    payload,
  } as const);

export const setFoliaSyntaxGuideOpen = (open: boolean, dialogMode?: boolean) =>
  ({
    type: ActionTypes.SET_FOLIA_SYNTAX_GUIDE_OPEN,
    payload: { open, dialogMode },
  } as const);

export const openManageConditionsModal = (sequenceId: number) =>
  ({
    type: ActionTypes.OPEN_MANAGE_CONDITIONS_MODAL,
    payload: {
      sequenceId,
    },
  } as const);

export const setManageConditionStyles = (styles: StyleRule[]) =>
  ({
    type: ActionTypes.SET_MANAGE_CONDITION_STYLES,
    payload: {
      styles,
    },
  } as const);

export const editConditionalStyle = (styleProps: StyleProps, styleId: string): AppThunk => (
  dispatch,
  getState,
) => {
  dispatch(
    setManageConditionStyles(
      getState().ui?.manageStylesModal?.conditionalStyles?.map(style => ({
        ...style,
        styleProps: style.styleId === styleId ? styleProps : style.styleProps,
      })) || [],
    ),
  );
};

export const closeManageConditionsModal = () =>
  ({
    type: ActionTypes.CLOSE_MANAGE_CONDITIONS_MODAL,
  } as const);

export const openHeatMapModal = (
  sequenceId: number,
  targetType: StyleTargetType,
  targets: StyleTarget[],
) =>
  ({
    type: ActionTypes.OPEN_HEAT_MAP_MODAL,
    payload: {
      sequenceId,
      targetType,
      targets,
    },
  } as const);

export const closeHeatMapModal = () =>
  ({
    type: ActionTypes.CLOSE_HEAT_MAP_MODAL,
  } as const);

export const openLevelFormatModal = (sequenceId: number) =>
  ({
    type: ActionTypes.OPEN_LEVEL_FORMAT_MODAL,
    payload: sequenceId,
  } as const);

export const editLevelFormatLevelStyles = (
  styleProps: StyleProps | null,
  levelToEdit: number,
): AppThunk => (dispatch, getState) => {
  const levelStyleData = getState().ui?.levelFormatModal?.levelStyleData;
  dispatch(
    setLevelFormatLevelStyles({
      ...levelStyleData,
      [levelToEdit]: styleProps ? [styleProps] : [],
    }),
  );
};

export const setLevelFormatLevelStyles = (levelStyleData: LevelStyleData) =>
  ({
    type: ActionTypes.SET_LEVEL_FORMAT_LEVEL_STYLES,
    payload: levelStyleData,
  } as const);

export const closeLevelFormatModal = () =>
  ({
    type: ActionTypes.CLOSE_LEVEL_FORMAT_MODAL,
  } as const);
