// @ts-strict-ignore
import { createObject, decomposeCellId } from 'algo-react-dataviz';
import deepEqual from 'deep-equal';
import {
  getCopyableStylePropsForSelection,
  getFirstSharedApplicableStyleRule,
} from '../../model/styles/styleRule';
import {
  StyleTarget,
  StyleTargetType,
  styleTargetTypesForSelection,
} from '../../model/styles/styleTarget';
import { DESIGNER_SEQUENCE_ID } from '../../shared/constants';
import {
  extractWorkspacePayloadAttr,
  isReservedSequenceId,
  resolveDefaultReportDefinition,
} from '../../shared/utils';
import { AppState } from '../configureStore';

export const isDesignerDirty = (state: AppState) => {
  const currentDefinition = state.report.reportDefinition[DESIGNER_SEQUENCE_ID];
  const matchingWorkspaceDefinition = Object.entries(state.report.reportDefinition).find(
    ([key, def]) => !isReservedSequenceId(Number(key)) && def.id === currentDefinition.id,
  );
  const baseReportDefinition = matchingWorkspaceDefinition
    ? matchingWorkspaceDefinition[1]
    : state.reportDesigner.panelControl.originalDetachedReport
    ? state.reportDesigner.panelControl?.originalDetachedReport
    : resolveDefaultReportDefinition(state);

  return !deepEqual(currentDefinition, baseReportDefinition);
};

export const getReportCardLayerId = (sequenceId: number) => (state: AppState) =>
  extractWorkspacePayloadAttr<string>('layerId', sequenceId, state);

export const isReportCardMaximized = (sequenceId: number) => (state: AppState) => {
  const layerId = getReportCardLayerId(sequenceId)(state);

  return layerId !== null && layerId === state.workspace.maximizedLayerId;
};

export const getAllowedStyleTargetTypesForSelection = (sequenceId: number) => (state: AppState) => {
  const reportDefinition = state.report.reportDefinition[sequenceId];
  const detailList = extractWorkspacePayloadAttr<boolean | undefined>(
    'detailList',
    sequenceId,
    state,
  );

  if (reportDefinition.legacyReport || detailList) {
    return createObject({
      items: styleTargetTypesForSelection,
      createValue: () => false,
    });
  }

  const cellIds =
    extractWorkspacePayloadAttr<string[]>('selectedElements', sequenceId, state) ?? [];

  const decomposedIds = cellIds.map(decomposeCellId);

  const rowIds = new Set(decomposedIds.map(({ rowId }) => rowId));
  const columnIds = new Set(decomposedIds.map(({ columnId }) => columnId));

  return {
    [StyleTargetType.CELL]: cellIds.length > 0,
    [StyleTargetType.ROW]: rowIds.size === 1,
    [StyleTargetType.COLUMN]: columnIds.size === 1,
  };
};

export const getApplicableUniformStyleRule = (
  sequenceId: number | null,
  targets: StyleTarget[],
  heatMap?: boolean,
) => (state: AppState) => {
  if (!sequenceId || targets.length === 0) {
    return null;
  }

  switch (targets[0].type) {
    case StyleTargetType.CELL:
    case StyleTargetType.ROW:
    case StyleTargetType.COLUMN: {
      return (
        getFirstSharedApplicableStyleRule(
          (state.report.reportDefinition[sequenceId]?.styleData?.uniformStyles ?? []).filter(rule =>
            heatMap ? !!rule.styleProps.heatMapStyleProps : !rule.styleProps.heatMapStyleProps,
          ),
          targets,
        )?.styleProps ?? null
      );
    }

    case StyleTargetType.NORMAL_GROUPING: {
      const target = targets[0];
      return (
        state.report.reportDefinition[sequenceId]?.[`${target.direction}Chars`]?.find(
          layer => layer.layerId === target.charId && layer.modifier === target.modifier,
        )?.styleProps ?? null
      );
    }
  }
};
export const getConditionalStyle = (sequenceId: number | null, styleId: string) => (
  state: AppState,
) => {
  if (!sequenceId || !styleId) {
    return null;
  }

  return state.report.reportDefinition[sequenceId]?.styleData?.uniformStyles.find(
    styleRule => styleRule.styleId === styleId,
  )?.styleProps;
};

export const getConditionalStyles = (sequenceId: number | null) => (state: AppState) => {
  if (!sequenceId) {
    return null;
  }
  return state.report.reportDefinition[sequenceId]?.styleData?.uniformStyles?.filter(
    style => !!style.styleProps.conditionalStyleProps,
  );
};

export const getCopyableStyleProps = (sequenceId: number | null, cellIds: string[]) => (
  state: AppState,
) =>
  sequenceId
    ? getCopyableStylePropsForSelection(
        state.report.reportDefinition[sequenceId]?.styleData?.uniformStyles ?? [],
        cellIds,
      )
    : createObject({
        items: Object.values(StyleTargetType),
        createValue: () => null,
      });
